<template>
  <div class="formdata" style="padding: 20px">
    <el-form ref="form" label-width="120px">
      <!-- <div
        v-infinite-scroll="load"
        style="overflow: auto,height:50vh"
      > -->
      <div v-for="item in historyList" :key="item.id">
        <template v-if="type !== '120'">
          <div v-if="patientIm === item.fromAccount" class="patient-color">
            <span class="patient-margin">患者-{{ orderInfo.patientName }}:</span
            ><span>{{ item.chatTime }}</span>
          </div>
          <div v-if="patientIm !== item.fromAccount" class="doctor-color">
            <span class="patient-margin">{{
              '医生-' + orderInfo.doctorName + ':'
            }}</span
            ><span>{{ item.chatTime }}</span>
          </div>
        </template>
        <template v-else>
          <div
            v-if="item.data.ext.channel === 'doctor' || !item.data.ext.channel"
            class="doctor-color"
          >
            <span class="doctor-margin">{{ item.data.fromNick }}</span>
            <span>{{ item.chatTime }}</span>
          </div>
          <div v-if="item.data.ext.channel === 'user'" class="patient-color">
            <span class="patient-margin">{{ item.data.fromNick }}</span>
            <span>{{ item.chatTime }}</span>
          </div>
        </template>

        <div class="historyTalk">
          <span v-if="!item.data.attach">{{ item.data.body }}</span>
          <template v-else>
            <template v-if="item.data.msgType === 'PICTURE'">
              <el-image
                class="avatar"
                :src="item.data.attach.url"
                fit="scale-down"
                :preview-src-list="[item.data.attach.url]"
                @click="solveRefresh()"
              />
            </template>
            <template v-if="item.data.msgType === 'AUDIO'">
              <audio
                :src="item.data.attach.url"
                :width="item.data.attach.w ? item.data.attach.w : 100"
                :height="item.data.attach.h ? item.data.attach.h : 100"
                type="audio/mpeg"
                controls
                volume="0"
              />
            </template>
            <template v-if="item.data.msgType === 'CUSTOM'">
              <!-- <el -->
              <el-button
                v-if="item.data.attach.type === 'patientInfo'"
                type="primary"
                @click="view(item, 1)"
              >
                病情详情
              </el-button>
              <el-button
                v-if="item.data.attach.type === 'prescription'"
                type="primary"
                @click="view(item, 2)"
              >
                电子处方
              </el-button>
              <el-button
                v-if="item.data.attach.type === 'consultationSummary'"
                type="primary"
                @click="view(item, 3)"
              >
                电子病历
              </el-button>
              <el-button
                v-if="item.data.attach.type === 'heartLoopInvitation'"
                type="primary"
                @click="view(item, 4)"
              >
                {{
                  item.data.attach.params.eventType === 'initiateInvitation'
                    ? '发起邀约'
                    : '接受邀约'
                }}
              </el-button>
              <template v-if="item.data.attach.type === 'customVideo'">
                <span v-if="item.data.attach.params.type === 'NO_REPLY'"
                  >发起视频，对方无法应答</span
                >
                <span v-if="item.data.attach.params.type === 'CALL_OFF'"
                  >已取消发起视频</span
                >
                <span v-if="item.data.attach.params.type === 'REFUSE'"
                  >发起视频，对方已拒绝</span
                >
                <template v-if="item.data.attach.params.type === 'NORMAL'">
                  <div
                    v-for="(item1, index) in item.data.attach.videos"
                    :key="index"
                  >
                    <video
                      width="400"
                      height="320"
                      style="margin: 10px"
                      class="videoStyle"
                      controls
                    >
                      <source
                        :src="item1.url.replace('http', 'https')"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </template>
              </template>

              <history-dialog
                v-if="customDialogVisible"
                :attach="attach"
                :patient-info="orderInfo"
                :visible="customDialogVisible"
                :title-type="cardName"
                @cancel="customDialogVisible = false"
              />
            </template>
          </template>
        </div>
      </div>
      <!-- </div> -->

      <template>
        <div class="btn">
          <el-button
            v-if="pageInfo.page !== 1"
            type="primary"
            @click="previousPage()"
          >
            上一页
          </el-button>
          <el-button v-if="isShowPage" type="primary" @click="nextPage()">
            下一页
          </el-button>
          <el-button @click="$router.go(-1)"> 返回 </el-button>
        </div>
      </template>
    </el-form>
    <!-- <div
      v-if="isShowPage"
      class="padding-30"
    >
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div> -->
  </div>
</template>

<script>
// import {data} from 'autoprefixer';
import HistoryDialog from '../dialog/index'; // 导入清单弹窗
export default {
  name: 'HistoryTalk',
  components: {
    HistoryDialog,
  },
  data() {
    return {
      customDialogVisible: false, // 电子处方、电子病历、患者信息弹框
      cardName: '',
      isShowPage: true,
      // info: JSON.parse(this.$route.query.info),
      // info: this.orderInfo,
      patientIm: '',
      form: {},
      attach: {}, // 组件传参
      historyList: [],
      orderInfo: {}, // 订单详情
      getExtend: {}, // extend详情
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      consultionDetail: {}, // 复诊订单详情
    };
  },
  computed: {
    type() {
      return this.$route.query.type;
    },
  },
  watch: {},
  mounted() {
    if (this.type !== '120') {
      this.getHistoryDetail();
    } else {
      this.furtherConsultionDetail();
    }
  },
  methods: {
    // 懒加载
    load() {
      this.pageInfo.page += 1;
      this.getHistoryDetail();
    },
    // 查看电子病历和处方
    view(item, type) {
      console.log('打印点击的类型', type);
      const {
        data: { attach },
      } = item;
      // console.log();
      this.attach = attach;
      this.customDialogVisible = true;
      switch (type) {
        case 1:
          this.cardName = '病情卡片';
          break;
        case 2:
          this.cardName = '电子处方';
          break;
        case 3:
          this.cardName = '电子病历';
          break;
        case 4:
          if (attach.params.eventType === 'initiateInvitation') {
            this.cardName = '发起邀约';
          } else {
            this.cardName = '接受邀约';
          }

          break;
        default:
          break;
      }
    },
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getConsultOrderList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getConsultOrderList();
    },

    // 历史记录详情
    getHistoryDetail() {
      const param1 = {
        orderNo: this.$route.query.orderNo,
      };
      this.$api
        .getOrderInfoBynum(param1)
        .then((res) => {
          if (res.code === 0) {
            console.log(res, 5555555555555555);
            this.orderInfo = res.data;
            this.getExtend = res.data.extend;
          }
          return;
        })
        .then(() => {
          const param = {
            // consultationOrderNo: this.$route.query.orderDetailNum,
            consultationOrderNo: this.$route.query.orderDetailNum,
            consultationType: this.$route.query.type,
            extend: this.getExtend,
            page: {
              done: true,
              pageIndex: this.pageInfo.page,
              pageSize: this.pageInfo.size,
            },
          };
          this.$api.queryConsultationTalkHistoryList(param).then((res) => {
            if (!res.data.last) {
              this.isShowPage = false;
            } else {
              this.isShowPage = true;
            }
            var list = [];
            // var extend = JSON.parse(this.$route.query.extend)
            var extend = JSON.parse(this.getExtend);
            console.log(extend, '777777777777777');

            this.patientIm = extend.patientIm;
            res.data.rows?.forEach((item) => {
              list.push({
                id: item.id,
                chatTime: item.chatTime,
                data: JSON.parse(item.data),
                fromAccount: item.fromAccount,
                toAccount: item.toAccount,
              });
            });
            list.forEach((item) => {
              if (item.data.attach) {
                item.data.attach = JSON.parse(item.data.attach);
              }
            });
            this.historyList = list;
            console.log(this.historyList, '222');
          });
        });
    },
    // el-image点击图片页面卡顿问题
    solveRefresh() {
      document.body.style = '';
    },
    // 下一页
    nextPage() {
      const { type, consultionDetail } = this;
      this.pageInfo.page++;
      if (type === '120') {
        this.furtherConsultionHistoryList(consultionDetail);
      } else {
        this.getHistoryDetail();
      }
    },
    // 上一页
    previousPage() {
      const { type, consultionDetail } = this;
      this.pageInfo.page--;
      if (type === '120') {
        this.furtherConsultionHistoryList(consultionDetail);
      } else {
        this.getHistoryDetail();
      }
    },
    // 复诊历史聊天记录
    furtherConsultionHistoryList(data) {
      const param = {
        consultationType: '120',
        imGroupId: data.record.imGroupId,
        consultationOrderNo: this.$route.query.orderDetailNum,
        // startTime: data.createTime,
        // endTime: data.endTime,
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      this.$api.queryGroupChatMessageList(param).then((res) => {
        console.log(res);
        const { data } = res;
        if (!data.last) {
          this.isShowPage = false;
        } else {
          this.isShowPage = true;
        }
        var list = [];
        data.rows?.forEach((item) => {
          list.push({
            id: item.id,
            chatTime: item.chatTime,
            data: JSON.parse(item.data),
            fromAccount: item.fromAccount,
            toAccount: item.toAccount,
          });
        });
        console.log(list, 'list');
        // list.forEach(item => {
        //   if(item.data.attach) {
        //     item.data.attach = JSON.parse(item.data.attach);
        //   }
        // });
        this.historyList = list;
        // console.log(this.historyList, '222');
      });
    },
    // 复诊订单详情
    furtherConsultionDetail() {
      const param = {
        id: this.$route.query.orderDetailNum,
      };
      this.$api.furtherConsultationDetail(param).then((res) => {
        const { data } = res;
        this.consultionDetail = data;
        this.furtherConsultionHistoryList(data);
      });
    },
  },
};
</script>

<style lang="scss">
// 预约时间的可预约样式
</style>
<style lang="scss" scoped>
.formdata {
  label {
    font-size: 14px;
    color: #606266;
  }

  .input_el {
    width: 30%;
  }
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.consultHistory {
  display: flex;
  //  justify-content:flex-start;
  align-items: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
  margin-right: 20px;
}

.patient-margin {
  margin-right: 10px;
}
.patient-color {
  color: #13ce66;
  margin-left: 120px;
}
.doctor-color {
  color: #1682e6;
  margin-left: 120px;
}
.btn {
  margin-left: 120px;
}
.historyTalk {
  margin: 20px 0px 20px 150px;
}
.videoStyle {
  height: 320px;
  width: 240px;
  margin: 10px;
}
</style>
